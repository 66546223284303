"use client";
import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Container, Typography, Link as MuiLink, IconButton } from '@mui/material';
import { YouTube, Instagram, LinkedIn, Facebook } from '@mui/icons-material';
import Logo_img from '../../public/logo.svg';
import PlayStore_img from '../../public/footer/Google_play.svg'
import AppStore_img from '../../public/footer/Apple_store.svg'
import Image from 'next/image';
import { variables } from '../styles/variables';

const FooterContainer = styled.footer`
  background: linear-gradient(180deg, #FFDFB9 0%, #FFEBD2 100%);
  color: #1E1E1E;
  padding: 2rem 0;
  margin-top: 40px;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 680px;

  @media (max-width: 768px) {
    align-items: flex-start;
    width: auto;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;

const FooterData = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;

    @media (max-width: 768px) {
    display: flex;
    justify-content: start;
  }
`;

const StyledLink = styled(MuiLink)`
  color: black;
  text-decoration: none;
  font-family: "Poppins", Helvetica;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;

const Footer = () => {

  const router = useRouter();

  const handleRedirect = (href) => {
    if (href) {
      if (href === "about" || href === "contact") {
        router.push('/#' + href);
      } else if (href === '/') {
        router.push('/');
      } else {
        router.push(href);
      }
    } else {
      window.open('https://onelink.to/s5pgev', '_blank');
    }
  };
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <FooterContent>
          <LeftSection>
            <Image
              src={Logo_img}
              alt="Logo"
              style={{ height: '42px', width: '169px' }}
            />
            <SocialIcons>
              <a
                href="https://www.facebook.com/pappadindia"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton color="inherit" aria-label="facebook">
                  <Facebook style={{ color: 'black', fontSize: '32px' }} />
                </IconButton>
              </a>
              <a
                href="https://www.instagram.com/pappad.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton color="inherit" aria-label="instagram">
                  <Instagram style={{ color: 'black', fontSize: '32px' }} />
                </IconButton>
              </a>
              <a
                href="https://www.youtube.com/@pappaddelivery"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton color="inherit" aria-label="youtube">
                  <YouTube style={{ color: 'black', fontSize: '32px' }} />
                </IconButton>
              </a>
              <a
                href="https://www.linkedin.com/company/pappad/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton color="inherit" aria-label="linkedin">
                  <LinkedIn style={{ color: 'black', fontSize: '32px' }} />
                </IconButton>
              </a>
            </SocialIcons>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/search?q=pappad&c=apps&hl=en">
              <Image
                src={PlayStore_img}
                alt="Play_store"
                style={{ height: '60px', width: '200px', marginTop: '40px' }}
              />
            </a>
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/in/app/pappad-delivery-app/id1609559334">
              <Image
                src={AppStore_img}
                alt="Apple_store"
                style={{ height: '60px', width: '200px', marginTop: '40px' }}
              />
            </a>
          </LeftSection>
          <RightSection>
            <FooterData>
              <QuickLinks>
                <Typography variant="h6" gutterBottom style={{ color: `${variables.primaryColor}`, fontSize: '18px', fontWeight: '600', fontFamily: '"Inter", Helvetica' }}>Quick Links</Typography>
                <StyledLink onClick={() => { handleRedirect("/policies/policy-privacy") }} color="inherit">Privacy Policy</StyledLink>
                <StyledLink onClick={() => { handleRedirect("/policies/refund-policy") }} color="inherit">Refund Policy</StyledLink>
                <StyledLink onClick={() => { handleRedirect("/policies/shipping-policy") }} color="inherit">Shiping Policy</StyledLink>
                <StyledLink onClick={() => { handleRedirect("about") }} color="inherit">About Us</StyledLink>
                <StyledLink onClick={() => { handleRedirect("contact") }} color="inherit">Contact Us</StyledLink>
                <StyledLink onClick={() => { handleRedirect("/policies/terms-conditions") }} color="inherit">Terms & Conditions</StyledLink>
                <StyledLink onClick={() => { handleRedirect("/policies/cancellation-policy") }} color="inherit">Cancellation Policy</StyledLink>
              </QuickLinks>
            </FooterData>
            <FooterData>
              <CityList>
                <Typography variant="h6" gutterBottom style={{ color: `${variables.primaryColor}`, fontSize: '18px', fontWeight: '600', fontFamily: '"Inter", Helvetica' }}>Contact</Typography>
                <StyledLink href="tel:+918943169969" color="inherit">Pattambi</StyledLink>
                <StyledLink href="tel:+918122968877" color="inherit">Trichy</StyledLink>
                <StyledLink href="tel:+917025573337" color="inherit">Palakkad</StyledLink>
                <StyledLink href="tel:+918489863088" color="inherit">Coimbatore</StyledLink>
                <StyledLink href="tel:+919500316857" color="inherit">Mettupalayam</StyledLink>
              </CityList>
            </FooterData>
          </RightSection>
        </FooterContent>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
